<template>
    <div class="navbar">
        <div class="data" @click="$router.push('/dataview')">
            <img src="../assets/img/data.png">
        </div>
        <el-menu :default-active="path" :unique-opened="true" :router="true">
            <template v-for="item in menuList">
                <template v-if="item.children">
                    <el-submenu :index="item.routeName" :key="item.id">
                        <template slot="title">
                            <img :src="item.icon">
                            <span>{{item.moduleName}}</span>
                        </template>
                        <el-menu-item v-for="child in item.children" :key="child.id" :index="child.routeName || item.routeName + '/' + child.id">{{child.moduleName || child.siteName}}</el-menu-item>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.routeName" :key="item.id">
                        <img :src="item.icon">
                        <span slot="title">{{item.moduleName}}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import icon1 from "../assets/img/icon_home.png"
import icon2 from "../assets/img/icon_list1.png"
import icon3 from "../assets/img/icon_list2.png"
import icon4 from "../assets/img/icon_manage.png"
import icon5 from "../assets/img/icon_fenxi.png"
import icon6 from "../assets/img/icon_baojin.png"
import icon7 from "../assets/img/icon_quanxian.png"
import icon8 from "../assets/img/icon_log.png"
import icon9 from "../assets/img/path.png"
import axios from "../request"
export default {
    name: "NavBar",
    data(){
        return {
            path: this.$router.currentRoute.fullPath,
            menuList: [],
            icons: {
                "/": icon1,//首页
                "/slimhome": icon1,//极简首页
                "/washroomlist": icon2,//公厕列表
                "/stationlist": icon3,//转运站列表
                "/archives": icon4,//档案管理
                "/statistics": icon5,//统计分析
                "/alarmrecord": icon6,//报警记录
                "/Permission": icon7,//权限管理
                "/log": icon8,//操作日志
                "/pathview": icon9,//轨迹查看
            }
        }
    },
    watch: {
        "$route": function(val){
            console.log(val)
            if (val.query == '') {
                this.path = val.fullPath
            } else {
                this.path = val.query.paths
            }
            
        }
    },
 
    async created(){
        console.log(this.$route.query.paths)
        if (this.$route.query.paths) {
             this.path = this.$route.query.paths

        }
       
        let id = localStorage.getItem("id") ?? sessionStorage.getItem("id");
        let data = await axios.get("/menu/findRole/" + id, {userId: id});
        if(data){
            data.forEach(async item => {
                item.icon = this.icons[item.routeName];
                if(item.routeName === "/washroomlist"){
                    // 获取公厕列表
                    let data = await axios.get("/toilet/findAll");
                    // let name={id: 'WashroomSummarizing',
                    // latitude: "37.474482",
                    // longitude: "118.496665",
                    // siteName: "公厕信息汇总",
                    // siteType: 1,
                    // status: 0,
                    // toiletId: null,
                    // transferPointStatus: null}
                    let name=[]
                    if(data)  name = data.sort((a, b) => a.id - b.id);
                    //item.children.concat(name)
                    item.children.push.apply(item.children, name)
                   // item.children.push(name)
                    console.log(item.children);
                }
                if(item.routeName === "/stationlist"){
                    // 获取转运站列表
                    let data = await axios.get("/transferPoint/findAll");
                    if(data) item.children = data.sort((a, b) => a.id - b.id);
                }
            })
            this.menuList = data;
        }
    }
};
</script>

<style scoped>
.navbar {
    width: 200px;
    border-right: 2px solid #3e87ff;
    background-color: white;
    height: 100%;
    overflow: auto;
    flex: none;
    box-shadow: 0px 2px 10px 0px rgba(62, 139, 255, 0.2);
}
.data{
    padding: 10px 15px 0;
    cursor: pointer;
}
</style>
